"use client"

import React, { ReactNode, RefObject, useEffect, useRef, useState } from "react"

import { useWindowResize } from "@/hooks/useWindowResize"
import LeftArrow from "@layout/icons/LeftArrow"
import RightArrow from "@layout/icons/RightArrow"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import { Paragraph } from "@ui-library/typography/Body"
import { cn, findColorName } from "@utils/utils"

interface NewsSectionProps {
  title: string
  content?: string
  background?: string
  centerHeading?: boolean
  cards: ReactNode[]
}

const CarouselSection = ({ title, content, background, cards }: NewsSectionProps) => {
  const bg = findColorName(background)
  const containerWrapperRef: RefObject<HTMLDivElement> = useRef(null)
  const itemRef: RefObject<HTMLDivElement> = useRef(null)
  const [index, setIndex] = useState(0)
  const [isSliding, setIsSliding] = useState(false)
  const [visibleCards, setVisibleCards] = useState(1)
  const VISIBLE_PERCENTAGE = 0.05

  const threshold = 50
  const left = -1
  const right = 1
  let startX: number

  const handleArrowClick = (direction: number) => {
    if (isSliding) return

    const maxIndex = cards.length - visibleCards
    const newIndex = index + direction

    if (containerWrapperRef.current && itemRef.current && newIndex <= maxIndex && newIndex >= 0) {
      setIsSliding(true)
      const cardOffset = itemRef.current.getBoundingClientRect().width * VISIBLE_PERCENTAGE
      containerWrapperRef.current.scrollLeft += (itemRef.current.getBoundingClientRect().width - cardOffset) * direction
      setIndex(newIndex)

      setTimeout(() => {
        setIsSliding(false)
      }, 300)
    }
  }

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    startX = event.touches[0].pageX
  }

  const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    const endX = event.changedTouches[0].pageX
    const difference = startX - endX

    if (difference > threshold) {
      handleArrowClick(right)
    } else if (difference < -threshold) {
      handleArrowClick(left)
    }
  }

  const updateVisibleCards = () => {
    if (containerWrapperRef.current && itemRef.current) {
      const containerWidth = containerWrapperRef.current.getBoundingClientRect().width
      const cardWidth = itemRef.current.getBoundingClientRect().width
      const numVisibleCards = Math.floor(containerWidth / cardWidth)
      setVisibleCards(numVisibleCards)
    }
  }

  useWindowResize(updateVisibleCards)

  useEffect(() => {
    if (containerWrapperRef.current && itemRef.current) {
      const partialView = itemRef.current.getBoundingClientRect().width * VISIBLE_PERCENTAGE
      const expectedPosition = index * itemRef.current.getBoundingClientRect().width - index * partialView
      if (containerWrapperRef.current.scrollLeft !== expectedPosition) {
        containerWrapperRef.current.scrollLeft = expectedPosition
      }
    }
  }, [index])

  return (
    <>
      <section
        className={cn(
          "mx-auto my-12 max-w-full md:my-16",
          bg === "surface" && "mx-0 rounded-3xl bg-surface py-12 md:mx-4 md:py-16"
        )}
      >
        <Row justifyContent="center" justifyContentMobile="center">
          <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="mb-6 text-left lg:mb-10 lg:text-center">
            {title && <h2>{title}</h2>}
            {content && <Paragraph size="medium">{content}</Paragraph>}
          </Col>
        </Row>

        <div className="carouselArea">
          <div
            ref={containerWrapperRef}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            className="mb-8 box-border w-full overflow-hidden scroll-smooth"
          >
            <Row className="flex-nowrap">
              {cards.map((card, idx) => (
                <CarouselItem isSingleCard={cards.length === 1} key={`${idx}-carousel-card`} ref={itemRef}>
                  {card}
                </CarouselItem>
              ))}
            </Row>
          </div>
        </div>

        {cards.length !== 1 && cards.length > 1 && cards.length > visibleCards && (
          <div className="flex justify-center gap-6">
            <button
              disabled={index === 0}
              onClick={() => handleArrowClick(left)}
              aria-label="Föregående slide"
              className={cn(
                "flex w-full cursor-pointer justify-end whitespace-nowrap border-0 bg-transparent p-4 text-on-surface",
                index === 0 && "pointer-events-none cursor-default text-neutral-30"
              )}
            >
              <LeftArrow />
            </button>
            <button
              disabled={index >= cards.length - visibleCards}
              onClick={() => handleArrowClick(right)}
              aria-label="Nästa slide"
              className={cn(
                "flex w-full cursor-pointer justify-start whitespace-nowrap border-0 bg-transparent p-4 text-on-surface",
                index >= cards.length - visibleCards && "pointer-events-none cursor-default text-neutral-30"
              )}
            >
              <RightArrow />
            </button>
          </div>
        )}
      </section>
    </>
  )
}

export default CarouselSection

type CarouselItemProps = {
  children: React.ReactNode
  isSingleCard?: boolean
}

const CarouselItem = React.forwardRef<HTMLDivElement, CarouselItemProps>(({ children, isSingleCard }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("m-[initial] flex-11 px-2 first:pl-4 last:pr-4 lg:flex-6", isSingleCard && "m-auto flex-12 pr-0")}
    >
      {children}
    </div>
  )
})

CarouselItem.displayName = "CarouselItem"
