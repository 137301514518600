import { Paragraph } from "@/components/ui-library/typography/Body"
import { H4 } from "@/components/ui-library/typography/Headings"
import { BlokProps } from "@/helpers/StoryblokHelpers"
import ChevronRightIcon from "@/layout/icons/ChevronRightIcon"
import { trackEvent } from "@/lib/amplitude/amplitude"
import { IconCardStoryblok } from "@/types/component-types"
import dynamic from "next/dynamic"
import Link from "next/link"
import { usePathname } from "next/navigation"

const DoktorIcon = dynamic(() => import("@layout/DoktorIcons"), {
  ssr: true
})

export const IconCard = ({ blok }: BlokProps<IconCardStoryblok>) => {
  const pathname = usePathname()
  const trackingEvent = blok.custom_tracking_event ?? blok.tracking_event

  let linkTarget = "_self"
  const isOnelink = blok.link?.cached_url?.includes("onelink")
  if (isOnelink) linkTarget = "_top"

  return (
    <Link
      href={blok.link?.cached_url || ""}
      target={linkTarget}
      className="relative flex h-full w-full items-center rounded-lg bg-surface p-6 shadow-elevation-1"
      onClick={trackingEvent ? () => trackEvent(trackingEvent, { page_path: pathname }) : undefined}
    >
      <DoktorIcon iconName={blok.icon || "DoctorIcon"} className="text-primary-50" width={42} height={42} />
      <div className="pl-4 pr-8 text-on-surface">
        <H4 className="m-0">{blok.heading}</H4>
        <Paragraph size="small" className="m-0">
          {blok.description}
        </Paragraph>
      </div>
      <ChevronRightIcon className="absolute right-6 top-2/4 h-auto w-2 -translate-y-2/4" />
    </Link>
  )
}

export default IconCard
