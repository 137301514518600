import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/utils/utils"
import React from "react"

const ParagraphVariants = cva("body leading-[1.45] font-normal last:mb-0 ", {
  variants: {
    size: {
      xsmall: "text-xs md:text-xs xl:text-sm font-text",
      small: "text-[0.9375rem] xl:text-base font-text",
      medium: "text-[1.125rem] xl:text-[1.25rem] tracking-[0.015em]",
      large: "text-[1.375rem] xl:text-[1.5625rem] tracking-[0.01em]"
    }
  },
  defaultVariants: {
    size: "small"
  }
})

export interface ParagraphProps
  extends React.ButtonHTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof ParagraphVariants> {
  asChild?: boolean
}

const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ className, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "p"

    return <Comp className={cn(ParagraphVariants({ size, className }))} ref={ref} {...props} />
  }
)

Paragraph.displayName = "Paragraph"

export { Paragraph, ParagraphVariants }
