import { ISbStoryData } from "@storyblok/react/rsc"

import Image from "next/image"
import Link from "next/link"

import { calculateReadingTimeFromMultidimensionalArray } from "@lib/calculateReadingTimeFromRichText"
import { formatDate } from "@lib/formatDate"
import { truncateStringByWords } from "@lib/truncateString"
import { urlParser } from "@lib/urlParser"
import { Paragraph } from "@ui-library/typography/Body"

export const ArticleCard = ({ article }: { article: ISbStoryData }) => {
  const formattedDate = formatDate(article.content.published_date)
  const readingTime = calculateReadingTimeFromMultidimensionalArray(article.content.body, article.content.preamble)
  const preamble = truncateStringByWords(article.content.preamble, 28)
  const excerpt = article.content.excerpt

  return (
    <Link
      href={urlParser(article.full_slug)}
      className="flex h-full flex-col justify-between overflow-hidden rounded-2xl bg-surface shadow-elevation-1"
    >
      <figure className="relative block h-64 w-full">
        {article.content.featured_image && (
          <Image
            src={article.content.featured_image.filename || ""}
            alt={article.name}
            quality={20}
            width={610}
            height={0}
            sizes="(max-width: 768px) 100vw, 33vw"
            className="absolute inset-0 h-full w-full object-cover object-top"
          />
        )}
      </figure>
      <div className="flex min-h-min flex-col justify-between px-8 py-6">
        <h3>{article.name}</h3>
        <Paragraph size="medium">{excerpt || preamble}</Paragraph>
      </div>

      <div className="mt-auto px-8 pb-6">
        <Paragraph size="small" asChild className="mb-0 mt-8 text-neutral-50">
          <span>
            {formattedDate} ・ {readingTime} min
          </span>
        </Paragraph>
      </div>
    </Link>
  )
}

export default ArticleCard
