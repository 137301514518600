import { ReactNode } from "react"
import { Paragraph } from "../typography/Body"

interface NewsCardProps {
  heading: string
  content: string
  link: ReactNode
}

const CarouselCard = ({ heading, content, link }: NewsCardProps) => {
  return (
    <article className="flex h-full min-h-[332px] flex-col whitespace-break-spaces break-words rounded-2xl bg-secondary-10 p-6 text-on-secondary-10 lg:p-8">
      <h3>{heading}</h3>
      <Paragraph size="small" className="grow">
        {content}
      </Paragraph>
      {link}
    </article>
  )
}

export default CarouselCard
