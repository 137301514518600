import { ISbStoryData, StoryblokComponent } from "@storyblok/react/rsc"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { PageStoryblok } from "@storyblok-types"
import PageContainer from "@ui-library/layout/PageContainer"

interface PageProps {
  blok: BlokProps<PageStoryblok>["blok"]
  latestArticles: ISbStoryData[]
}

export const Page = ({ blok, latestArticles }: PageProps) => {
  return (
    <>
      <PageContainer background={blok.background} {...withStoryblokEditable(blok)}>
        {blok.body?.map(nestedBlok => (
          <StoryblokComponent blok={nestedBlok} latestArticles={latestArticles} key={nestedBlok._uid} />
        ))}
      </PageContainer>
    </>
  )
}

export default Page
