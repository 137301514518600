"use client"

import { initAmplitude } from "@/lib/amplitude/amplitude"
import Script from "next/script"
import { useEffect } from "react"

export const TrackingProvider = () => {
  useEffect(() => {
    initAmplitude()
  }, [])

  return (
    <>
      {process.env.COOKIEBOT_ID && (
        <Script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={process.env.COOKIEBOT_ID}
          data-blockingmode="auto"
          type="text/javascript"
          strategy="afterInteractive"
        />
      )}

      {process.env.GTM_ID && (
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','${process.env.GTM_ID}');
					`}
        </Script>
      )}
    </>
  )
}
