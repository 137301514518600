import { StoryblokComponent } from "@storyblok/react/rsc"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { ClinicPageStoryblok } from "@storyblok-types"
import PageContainer from "@ui-library/layout/PageContainer"

interface ClinicPageProps {
  blok: BlokProps<ClinicPageStoryblok>["blok"]
}

export const ClinicPage = ({ blok }: ClinicPageProps) => {
  return (
    <>
      <PageContainer background={blok.background} extraHeight={false} {...withStoryblokEditable(blok)}>
        {blok.body?.map(nestedBlok => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}
      </PageContainer>
    </>
  )
}

export default ClinicPage
